import React from "react";

type Props = {
  className?: string;
  color?: string;
  style?: React.CSSProperties;
};

const Spinner: React.FC<Props> = ({
  className = "spin",
  style,
  color = "rgb(249, 18, 107)",
}) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 140 140"
  >
    <g className="circleGroup" transform="translate(70, 70)">
      <g className="circle">
        <path
          d="M3.800487233353953e-15,-62.06666666666666A62.06666666666666,62.06666666666666,0,0,1,21.228050229079845,-58.32358866344537L0,0Z"
          style={{ fill: color, opacity: 0 }}
        />
        <path
          d="M10.77776356052748,-61.123734536957706A62.06666666666666,62.06666666666666,0,0,1,31.033333333333324,-53.75131006155416L0,0Z"
          style={{ fill: color, opacity: 0.00833333 }}
        />
        <path
          d="M21.228050229079845,-58.32358866344537A62.06666666666666,62.06666666666666,0,0,1,39.89568430787787,-47.5458251029179L0,0Z"
          style={{ fill: color, opacity: 0.0166667 }}
        />
        <path
          d="M31.033333333333324,-53.75131006155416A62.06666666666666,62.06666666666666,0,0,1,47.5458251029179,-39.895684307877865L0,0Z"
          style={{ fill: color, opacity: 0.025 }}
        />
        <path
          d="M39.89568430787787,-47.5458251029179A62.06666666666666,62.06666666666666,0,0,1,53.75131006155415,-31.03333333333333L0,0Z"
          style={{ fill: color, opacity: 0.0333333 }}
        />
        <path
          d="M47.5458251029179,-39.895684307877865A62.06666666666666,62.06666666666666,0,0,1,58.32358866344537,-21.22805022907984L0,0Z"
          style={{ fill: color, opacity: 0.0416667 }}
        />
        <path
          d="M53.75131006155415,-31.03333333333333A62.06666666666666,62.06666666666666,0,0,1,61.123734536957706,-10.777763560527477L0,0Z"
          style={{ fill: color, opacity: 0.05 }}
        />
        <path
          d="M58.32358866344537,-21.22805022907984A62.06666666666666,62.06666666666666,0,0,1,62.06666666666666,0L0,0Z"
          style={{ fill: color, opacity: 0.0583333 }}
        />
        <path
          d="M61.123734536957706,-10.777763560527477A62.06666666666666,62.06666666666666,0,0,1,61.123734536957706,10.777763560527477L0,0Z"
          style={{ fill: color, opacity: 0.0666667 }}
        />
        <path
          d="M62.06666666666666,0A62.06666666666666,62.06666666666666,0,0,1,58.32358866344537,21.228050229079855L0,0Z"
          style={{ fill: color, opacity: 0.075 }}
        />
        <path
          d="M61.123734536957706,10.777763560527477A62.06666666666666,62.06666666666666,0,0,1,53.75131006155416,31.03333333333332L0,0Z"
          style={{ fill: color, opacity: 0.0833333 }}
        />
        <path
          d="M58.32358866344537,21.228050229079855A62.06666666666666,62.06666666666666,0,0,1,47.5458251029179,39.89568430787787L0,0Z"
          style={{ fill: color, opacity: 0.0916667 }}
        />
        <path
          d="M53.75131006155416,31.03333333333332A62.06666666666666,62.06666666666666,0,0,1,39.89568430787788,47.545825102917895L0,0Z"
          style={{ fill: color, opacity: 0.1 }}
        />
        <path
          d="M47.5458251029179,39.89568430787787A62.06666666666666,62.06666666666666,0,0,1,31.033333333333324,53.75131006155416L0,0Z"
          style={{ fill: color, opacity: 0.108333 }}
        />
        <path
          d="M39.89568430787788,47.545825102917895A62.06666666666666,62.06666666666666,0,0,1,21.228050229079845,58.32358866344537L0,0Z"
          style={{ fill: color, opacity: 0.116667 }}
        />
        <path
          d="M31.033333333333324,53.75131006155416A62.06666666666666,62.06666666666666,0,0,1,10.777763560527468,61.12373453695771L0,0Z"
          style={{ fill: color, opacity: 0.125 }}
        />
        <path
          d="M21.228050229079845,58.32358866344537A62.06666666666666,62.06666666666666,0,0,1,3.800487233353953e-15,62.06666666666666L0,0Z"
          style={{ fill: color, opacity: 0.133333 }}
        />
        <path
          d="M10.777763560527468,61.12373453695771A62.06666666666666,62.06666666666666,0,0,1,-10.777763560527488,61.123734536957706L0,0Z"
          style={{ fill: color, opacity: 0.141667 }}
        />
        <path
          d="M3.800487233353953e-15,62.06666666666666A62.06666666666666,62.06666666666666,0,0,1,-21.228050229079837,58.32358866344538L0,0Z"
          style={{ fill: color, opacity: 0.15 }}
        />
        <path
          d="M-10.777763560527488,61.123734536957706A62.06666666666666,62.06666666666666,0,0,1,-31.033333333333346,53.75131006155415L0,0Z"
          style={{ fill: color, opacity: 0.158333 }}
        />
        <path
          d="M-21.228050229079837,58.32358866344538A62.06666666666666,62.06666666666666,0,0,1,-39.89568430787789,47.54582510291789L0,0Z"
          style={{ fill: color, opacity: 0.166667 }}
        />
        <path
          d="M-31.033333333333346,53.75131006155415A62.06666666666666,62.06666666666666,0,0,1,-47.545825102917895,39.89568430787788L0,0Z"
          style={{ fill: color, opacity: 0.175 }}
        />
        <path
          d="M-39.89568430787789,47.54582510291789A62.06666666666666,62.06666666666666,0,0,1,-53.75131006155414,31.033333333333353L0,0Z"
          style={{ fill: color, opacity: 0.183333 }}
        />
        <path
          d="M-47.545825102917895,39.89568430787788A62.06666666666666,62.06666666666666,0,0,1,-58.32358866344538,21.22805022907982L0,0Z"
          style={{ fill: color, opacity: 0.191667 }}
        />
        <path
          d="M-53.75131006155414,31.033333333333353A62.06666666666666,62.06666666666666,0,0,1,-61.123734536957706,10.777763560527472L0,0Z"
          style={{ fill: color, opacity: 0.2 }}
        />
        <path
          d="M-58.32358866344538,21.22805022907982A62.06666666666666,62.06666666666666,0,0,1,-62.06666666666666,7.600974466707905e-15L0,0Z"
          style={{ fill: color, opacity: 0.208333 }}
        />
        <path
          d="M-61.123734536957706,10.777763560527472A62.06666666666666,62.06666666666666,0,0,1,-61.12373453695771,-10.777763560527456L0,0Z"
          style={{ fill: color, opacity: 0.216667 }}
        />
        <path
          d="M-62.06666666666666,7.600974466707905e-15A62.06666666666666,62.06666666666666,0,0,1,-58.32358866344537,-21.22805022907986L0,0Z"
          style={{ fill: color, opacity: 0.225 }}
        />
        <path
          d="M-61.12373453695771,-10.777763560527456A62.06666666666666,62.06666666666666,0,0,1,-53.75131006155415,-31.03333333333334L0,0Z"
          style={{ fill: color, opacity: 0.233333 }}
        />
        <path
          d="M-58.32358866344537,-21.22805022907986A62.06666666666666,62.06666666666666,0,0,1,-47.54582510291791,-39.895684307877865L0,0Z"
          style={{ fill: color, opacity: 0.241667 }}
        />
        <path
          d="M-53.75131006155415,-31.03333333333334A62.06666666666666,62.06666666666666,0,0,1,-39.89568430787788,-47.545825102917895L0,0Z"
          style={{ fill: color, opacity: 0.25 }}
        />
        <path
          d="M-47.54582510291791,-39.895684307877865A62.06666666666666,62.06666666666666,0,0,1,-31.03333333333336,-53.75131006155414L0,0Z"
          style={{ fill: color, opacity: 0.258333 }}
        />
        <path
          d="M-39.89568430787788,-47.545825102917895A62.06666666666666,62.06666666666666,0,0,1,-21.228050229079827,-58.32358866344538L0,0Z"
          style={{ fill: color, opacity: 0.266667 }}
        />
        <path
          d="M-31.03333333333336,-53.75131006155414A62.06666666666666,62.06666666666666,0,0,1,-10.777763560527475,-61.123734536957706L0,0Z"
          style={{ fill: color, opacity: 0.275 }}
        />
        <path
          d="M-21.228050229079827,-58.32358866344538A62.06666666666666,62.06666666666666,0,0,1,-1.1401461700061857e-14,-62.06666666666666L0,0Z"
          style={{ fill: color, opacity: 0.283333 }}
        />
        <path
          d="M-10.777763560527475,-61.123734536957706A62.06666666666666,62.06666666666666,0,0,1,-1.1401461700061857e-14,-62.06666666666666L0,0Z"
          style={{ fill: color, opacity: 0.291667 }}
        />
        <path
          d="M1.9002436166769763e-15,-31.03333333333333A31.03333333333333,31.03333333333333,0,0,1,10.614025114539922,-29.161794331722685L0,0Z"
          style={{ fill: color, opacity: 0 }}
        />
        <path
          d="M5.38888178026374,-30.561867268478853A31.03333333333333,31.03333333333333,0,0,1,15.516666666666662,-26.87565503077708L0,0Z"
          style={{ fill: color, opacity: 0.0277778 }}
        />
        <path
          d="M10.614025114539922,-29.161794331722685A31.03333333333333,31.03333333333333,0,0,1,19.947842153938936,-23.77291255145895L0,0Z"
          style={{ fill: color, opacity: 0.0555556 }}
        />
        <path
          d="M15.516666666666662,-26.87565503077708A31.03333333333333,31.03333333333333,0,0,1,23.77291255145895,-19.947842153938932L0,0Z"
          style={{ fill: color, opacity: 0.0833333 }}
        />
        <path
          d="M19.947842153938936,-23.77291255145895A31.03333333333333,31.03333333333333,0,0,1,26.875655030777075,-15.516666666666666L0,0Z"
          style={{ fill: color, opacity: 0.111111 }}
        />
        <path
          d="M23.77291255145895,-19.947842153938932A31.03333333333333,31.03333333333333,0,0,1,29.161794331722685,-10.61402511453992L0,0Z"
          style={{ fill: color, opacity: 0.138889 }}
        />
        <path
          d="M26.875655030777075,-15.516666666666666A31.03333333333333,31.03333333333333,0,0,1,30.561867268478853,-5.3888817802637385L0,0Z"
          style={{ fill: color, opacity: 0.166667 }}
        />
        <path
          d="M29.161794331722685,-10.61402511453992A31.03333333333333,31.03333333333333,0,0,1,31.03333333333333,0L0,0Z"
          style={{ fill: color, opacity: 0.194444 }}
        />
        <path
          d="M30.561867268478853,-5.3888817802637385A31.03333333333333,31.03333333333333,0,0,1,30.561867268478853,5.3888817802637385L0,0Z"
          style={{ fill: color, opacity: 0.222222 }}
        />
        <path
          d="M31.03333333333333,0A31.03333333333333,31.03333333333333,0,0,1,29.161794331722685,10.614025114539928L0,0Z"
          style={{ fill: color, opacity: 0.25 }}
        />
        <path
          d="M30.561867268478853,5.3888817802637385A31.03333333333333,31.03333333333333,0,0,1,26.87565503077708,15.51666666666666L0,0Z"
          style={{ fill: color, opacity: 0.277778 }}
        />

        <path
          d="M29.161794331722685,10.614025114539928A31.03333333333333,31.03333333333333,0,0,1,23.77291255145895,19.947842153938936L0,0Z"
          style={{ fill: color, opacity: 0.305556 }}
        />
        <path
          d="M26.87565503077708,15.51666666666666A31.03333333333333,31.03333333333333,0,0,1,19.94784215393894,23.772912551458948L0,0Z"
          style={{ fill: color, opacity: 0.333333 }}
        />
        <path
          d="M23.77291255145895,19.947842153938936A31.03333333333333,31.03333333333333,0,0,1,15.516666666666662,26.87565503077708L0,0Z"
          style={{ fill: color, opacity: 0.361111 }}
        />
        <path
          d="M19.94784215393894,23.772912551458948A31.03333333333333,31.03333333333333,0,0,1,10.614025114539922,29.161794331722685L0,0Z"
          style={{ fill: color, opacity: 0.388889 }}
        />
        <path
          d="M15.516666666666662,26.87565503077708A31.03333333333333,31.03333333333333,0,0,1,5.388881780263734,30.561867268478856L0,0Z"
          style={{ fill: color, opacity: 0.416667 }}
        />
        <path
          d="M10.614025114539922,29.161794331722685A31.03333333333333,31.03333333333333,0,0,1,1.9002436166769763e-15,31.03333333333333L0,0Z"
          style={{ fill: color, opacity: 0.444444 }}
        />
        <path
          d="M5.388881780263734,30.561867268478856A31.03333333333333,31.03333333333333,0,0,1,-5.388881780263744,30.561867268478853L0,0Z"
          style={{ fill: color, opacity: 0.472222 }}
        />
        <path
          d="M1.9002436166769763e-15,31.03333333333333A31.03333333333333,31.03333333333333,0,0,1,-10.614025114539919,29.16179433172269L0,0Z"
          style={{ fill: color, opacity: 0.5 }}
        />
        <path
          d="M-5.388881780263744,30.561867268478853A31.03333333333333,31.03333333333333,0,0,1,-15.516666666666673,26.875655030777075L0,0Z"
          style={{ fill: color, opacity: 0.527778 }}
        />
        <path
          d="M-10.614025114539919,29.16179433172269A31.03333333333333,31.03333333333333,0,0,1,-19.947842153938947,23.772912551458944L0,0Z"
          style={{ fill: color, opacity: 0.555556 }}
        />
        <path
          d="M-15.516666666666673,26.875655030777075A31.03333333333333,31.03333333333333,0,0,1,-23.772912551458948,19.94784215393894L0,0Z"
          style={{ fill: color, opacity: 0.583333 }}
        />
        <path
          d="M-19.947842153938947,23.772912551458944A31.03333333333333,31.03333333333333,0,0,1,-26.87565503077707,15.516666666666676L0,0Z"
          style={{ fill: color, opacity: 0.611111 }}
        />
        <path
          d="M-23.772912551458948,19.94784215393894A31.03333333333333,31.03333333333333,0,0,1,-29.16179433172269,10.61402511453991L0,0Z"
          style={{ fill: color, opacity: 0.638889 }}
        />
        <path
          d="M-26.87565503077707,15.516666666666676A31.03333333333333,31.03333333333333,0,0,1,-30.561867268478853,5.388881780263736L0,0Z"
          style={{ fill: color, opacity: 0.666667 }}
        />
        <path
          d="M-29.16179433172269,10.61402511453991A31.03333333333333,31.03333333333333,0,0,1,-31.03333333333333,3.800487233353953e-15L0,0Z"
          style={{ fill: color, opacity: 0.694444 }}
        />
        <path
          d="M-30.561867268478853,5.388881780263736A31.03333333333333,31.03333333333333,0,0,1,-30.561867268478856,-5.388881780263728L0,0Z"
          style={{ fill: color, opacity: 0.722222 }}
        />
        <path
          d="M-31.03333333333333,3.800487233353953e-15A31.03333333333333,31.03333333333333,0,0,1,-29.161794331722685,-10.61402511453993L0,0Z"
          style={{ fill: color, opacity: 0.75 }}
        />
        <path
          d="M-30.561867268478856,-5.388881780263728A31.03333333333333,31.03333333333333,0,0,1,-26.875655030777075,-15.51666666666667L0,0Z"
          style={{ fill: color, opacity: 0.777778 }}
        />
        <path
          d="M-29.161794331722685,-10.61402511453993A31.03333333333333,31.03333333333333,0,0,1,-23.772912551458955,-19.947842153938932L0,0Z"
          style={{ fill: color, opacity: 0.805556 }}
        />
        <path
          d="M-26.875655030777075,-15.51666666666667A31.03333333333333,31.03333333333333,0,0,1,-19.94784215393894,-23.772912551458948L0,0Z"
          style={{ fill: color, opacity: 0.833333 }}
        />
        <path
          d="M-23.772912551458955,-19.947842153938932A31.03333333333333,31.03333333333333,0,0,1,-15.51666666666668,-26.87565503077707L0,0Z"
          style={{ fill: color, opacity: 0.861111 }}
        />
        <path
          d="M-19.94784215393894,-23.772912551458948A31.03333333333333,31.03333333333333,0,0,1,-10.614025114539913,-29.16179433172269L0,0Z"
          style={{ fill: color, opacity: 0.888889 }}
        />
        <path
          d="M-15.51666666666668,-26.87565503077707A31.03333333333333,31.03333333333333,0,0,1,-5.388881780263738,-30.561867268478853L0,0Z"
          style={{ fill: color, opacity: 0.916667 }}
        />
        <path
          d="M-10.614025114539913,-29.16179433172269A31.03333333333333,31.03333333333333,0,0,1,-5.7007308500309286e-15,-31.03333333333333L0,0Z"
          style={{ fill: color, opacity: 0.944444 }}
        />
        <path
          d="M-5.388881780263738,-30.561867268478853A31.03333333333333,31.03333333333333,0,0,1,-5.7007308500309286e-15,-31.03333333333333L0,0Z"
          style={{ fill: color, opacity: 0.972222 }}
        />
        <line
          x1="0"
          y1="0"
          x2="0"
          y2="-34.83333333333333"
          style={{ stroke: color, strokeWidth: 1.5 }}
        />
      </g>
    </g>
  </svg>
);

export default Spinner;
